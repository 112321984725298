<template>
    <v-snackbar v-model="isShow" :timeout="timeout" color="info">{{text}}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="isShow = false" icon small><v-icon small>mdi-close-circle</v-icon></v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
  name: 'TokotonMessageBar',
  data: () => ({
    isShow: false,
    text: '',
    timeout: -1
  }),
  methods: {
    show: function (message, timeout) {
      this.text = message
      this.timeout = timeout || 3000
      this.isShow = true
    }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep .v-snack__content{
    font-weight: bold;
  }
</style>
