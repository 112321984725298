<template>
  <div>
  <slot name="activator" v-bind:on="slotOn"></slot>
  <v-dialog v-model="isShow" :max-width="width">
    <v-card>
      <v-card-title><slot name="title"></slot></v-card-title>
      <v-card-text>
        <v-alert text :color="color" class="mt-2" v-if="$slots.message">
          <slot name="message"></slot>
        </v-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">キャンセル</v-btn>
        <v-btn :color="color" @click="accept">{{ btnTitle }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialogue',
  props: {
    type: {
      type: String,
      default: 'warning'
    },
    width: {
      type: String,
      default: '450'
    },
    btnTitle: {
      type: String,
      default: '削除'
    }
  },
  data: () => ({
    isShow: false
  }),
  computed: {
    color: function () {
      return this.type
    }
  },
  methods: {
    cancel: function () {
      console.info('cancel')
      this.$emit('cancel')
      this.isShow = false
    },
    accept: function () {
      this.isShow = false
      console.info('accept')
      this.$emit('accept')
    },
    slotOn: function () {
      this.isShow = true
    }
  }
}
</script>
