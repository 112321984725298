<template>
  <div id="plan">
   <v-toolbar flat class="mb-4">
      <v-spacer />

    <!-- Plan CRUD Dialog -->
    <v-dialog v-model="plans.crud.dialog" max-width="780px" @click:outside="closePlanDialog()" scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" @click="initialDialog"><v-icon left>mdi-plus-circle</v-icon>プラン追加</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="">{{ formTitleService }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-form ref="form" v-model="plans.crud.isFormValid">
            <v-container class="pa-0 pa-md-4">
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="plans.crud.crudModel.contract_model" label="プランタイプ" :rules="[inputRules.required]" hide-details persistent-hint class="mt-0">
                    <v-radio v-for="(type, i) in planType.items" :key="i" :value="type.contractModel">
                      <template v-slot:label>
                        <v-list-item>
                        <v-list-item-icon>
                          <v-img v-if="type.title == 'ポイント契約'" src="@/assets/ico/ico_point.svg" width="50px" />
                          <v-img v-else src="@/assets/ico/ico_unlimited.svg" width="50px" />
                        </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="type.title"></v-list-item-title>
                            <v-list-item-subtitle v-text="type.description"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" ><v-text-field v-model="plans.crud.crudModel.plan_name" class="required" label="プラン名" :rules="[inputRules.required]" hide-details="auto" validate-on-blur /></v-col>
                <v-col cols="12" >
                  <v-text-field v-model="plans.crud.crudModel.services" :rules="[inputRules.required]" v-show="false" />
                  <!-- selected services -->
                  <v-card outlined>
                    <v-card-title class="text-subtitle-1"><span class="required">サービス</span>
                      <v-spacer />
                      <v-dialog persistent v-model="plans.crud.serviceDialog" max-width="600px" scrollable>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="primary"><v-icon left>mdi-plus-circle</v-icon>サービス選択</v-btn>
                        </template>
                        <v-card>
                          <v-card-title>サービス選択</v-card-title>
                          <v-divider />
                          <v-card-text style="height:600px;">
                              <v-row dense>
                                <v-col cols="12">
                                  <v-simple-table v-for="cat in categories.items" :key="cat.id" class="mb-2 mt-5" dense>
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th colspan="2" class="primary--text">{{ cat.name }}</th>
                                          <th width="30" class="px-0">
                                            <v-btn title="このカテゴリ全てを追加" elevation="0" class="px-2 my-1" @click="addSelectedCategory(cat.id)"><v-icon size="20" small class="mr-1">mdi-checkbox-multiple-marked-outline</v-icon>全て追加</v-btn>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="item in getItemByCategory(cat.id)" :key="item.id">
                                          <td width="30" class="pr-0"><v-checkbox v-model="plans.crud.crudModel.selectedServices" :value="item.id" hide-details class="ma-0 pa-0" /></td>
                                          <td class="pl-0" colspan="2">{{ item.service }}</td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-col>
                              </v-row>
                          </v-card-text>
                          <v-divider />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn color="" text @click="plans.crud.serviceDialog = false">キャンセル</v-btn>
                            <v-btn color="primary" @click="serviceSelectionConfirm()">追加</v-btn>
                            <v-spacer />
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                    </v-card-title>
                    <v-card-text class="">
                      <template>
                        <v-simple-table v-for="[category, services] in plans.crud.crudModel.services" :key="category" dense class="mt-4 mb-3">
                          <template v-slot:default>
                            <thead>
                              <tr>
                              <th class=" primary--text">{{ category }}</th>
                              <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(serv) in services" :key="serv.id">
                                <td class="pl-4">{{ serv.service }}</td>
                                <td v-if="plans.crud.crudModel.contract_model == 'point'" class="pr-4">
                                  <v-text-field v-model="serv.__point" class="centered-input" type="number" dense hide-details="auto" :suffix="getPlanUnit(plans.crud.crudModel.contract_model)" :rules="[inputRules.required, inputRules.nonNegativeNum]" min="0">{{ serv.__point }}</v-text-field>
                                </td>
                                <td v-else class="text-right pr-4 text-subtitle-1">
                                  {{ getPlanUnit(plans.crud.crudModel.contract_model) }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" ><v-textarea v-model="plans.crud.crudModel.plan_memo" label="備考" hide-details rows="3" validate-on-blur outlined auto-grow /></v-col>
                <v-col cols="12" ><v-textarea v-model="plans.crud.crudModel.plan_admin_memo" label="社内メモ" placeholder="クライアントには表示されません" outlined hide-details auto-grow rows="3" validate-on-blur /></v-col>
              </v-row>
            </v-container>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closePlanDialog()">キャンセル</v-btn>
            <v-btn v-promise-btn v-if="plans.crud.editedIndex !== -1" class="primary" @click="savePlan()" :disabled="!plans.crud.isFormValid">更新</v-btn>
            <v-btn v-promise-btn v-else class="primary" @click="savePlan()" :disabled="!plans.crud.isFormValid">追加</v-btn>
            <v-spacer></v-spacer>
              <!-- 確認用ダイアログ -->
              <confirm-dialog v-if="plans.crud.editedIndex > -1" btnTitle = "削除" @accept="deletePlan">
                <template v-slot:title>このサービスを削除しますか？</template>
                <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
                <template v-slot:activator="{ on }">
                <v-btn icon v-on:click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
                </template>
              </confirm-dialog>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-toolbar>

    <!-- No plan data -->
    <template v-if="loading">
      <div>
        <v-progress-linear indeterminate color="blue"></v-progress-linear>
      </div>
    </template>
    <v-container v-else-if="!plans.items.length">
      <info-box>プランが登録されていません。<br><strong>プラン追加</strong>からプランを作成してください。</info-box>
    </v-container>
    <template v-else v-for="(ptype,i) in planType.items">
      <v-container :key="i" >
        <h4 class="mb-3 d-flex">
          <img v-if="ptype.contractModel == 'point'" src="@/assets/ico/ico_point.svg" width="22" class="mr-1" />
          <img v-else-if="ptype.contractModel == 'unlimit'" src="@/assets/ico/ico_unlimited.svg" width="22" class="mr-1" />
          {{ ptype.title }}
        </h4>
        <v-expansion-panels>
          <template v-for="(plan, i) in plans.items">
            <v-expansion-panel :key="i" v-if="ptype.contractModel === plan.contract_model">
              <v-expansion-panel-header class="subtitle-1">
                <span>{{ plan.plan_name }}
                  <div class="edit-plan ml-5">
                    <!-- TODO: 複製機能が未実装 -->
                    <!-- <v-btn icon small class="mr-1" @click.stop="copyPlan(plan)" title="プランの複製">
                      <v-icon size="18">mdi-content-copy</v-icon>
                    </v-btn> -->
                    <v-btn icon small class="" @click.stop="editPlan(plan)" title="プランの編集">
                      <v-icon size="18">mdi-pencil</v-icon>
                    </v-btn>
                    <!-- TODO: 削除機能が未実装 -->
                  </div>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template>
                  <v-simple-table v-for="[category, services] in plan.services" :key="category" dense class="pb-2">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="pl-0">{{ category }}</th>
                          <th width="50" class="text-center"></th>
                          <th width="70"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(service, j) in services" :key="j">
                          <td v-if="service.point_mst" class="pl-4">{{ service.point_mst.service }}</td>
                          <td v-else class="pl-4">名前なし</td>
                          <td>
                            <v-tooltip left max-width="500">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on">mdi-comment-text-outline</v-icon>
                              </template>
                              <pre>{{ service.point_mst.point_content }}</pre>
                            </v-tooltip>
                          </td>
                          <td v-if="plan.contract_model == 'point'" class="text-right pr-4"><span class="text-h6">{{ service.point }}</span><span class="unit">{{ getPlanUnit(plan.contract_model) }}</span></td>
                          <td v-else class="text-right pr-4"><span class="text-h6">{{ getPlanUnit(plan.contract_model) }}</span></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="more">
                    <details v-if="plan.plan_memo">
                      <summary>備考</summary>
                      <pre>{{plan.plan_memo}}</pre>
                    </details>
                    <details v-if="plan.plan_admin_memo">
                      <summary>社内メモ</summary>
                      <pre>{{plan.plan_admin_memo}}</pre>
                    </details>
                  </div>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-container>
    </template>

    <message-bar ref="msgbar" />

  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import MessageBar from '@/components/MessageBar.vue'
import InfoBox from '../../components/InfoBox.vue'
import axios from '@/plugins/axios'
export default {
  components: { ConfirmDialog, MessageBar, InfoBox },
  name: 'plan',
  data: function () {
    return {
      loading: true,
      tableHeaders: [
        { text: 'サービス名', value: 'serviceName', cellClass: 'service-title' },
        { text: '消費', value: 'maxUseCount', cellClass: 'service-consumption' }
      ],
      planType: {
        items: [
          { contractModel: 'point', title: 'ポイント契約', description: '購入ポイントから依頼内容によってポイントを減らしていく契約です。\n不透明な作業やサービスが発生しづらくトコトンがお勧めする契約プランです。' },
          { contractModel: 'unlimit', title: '無制限契約', description: '回数等の制限が無い契約です。\n契約期間中であれば何度でも依頼を出すことができます。' }
        ],
        nums: []
      },
      categories: {
        items: []
      },
      service: {
        items: []
      },
      plans: {
        crud: {
          dialog: false,
          serviceDialog: false,
          editedIndex: -1,
          isFormValid: false,
          crudModel: {
            id: null,
            contract_model: 'point',
            plan_name: '',
            plan_mst_service: [],
            services: null, // Organized map by category
            plan_memo: '',
            plan_admin_memo: '',
            selectedServices: [], // services for checkbox list
            isAllSelectedCategory: [],
            serviceList: [] // selected service object list (with updated consumption)
          },
          editedItem: {
            planType: 0,
            title: '',
            services: [],
            plan_memo: '',
            plan_admin_memo: ''
          }
        },
        items: []
      }
    }
  },
  methods: {
    initialDialog () {
      this.plans.crud.crudModel.selectedServices = []
      this.plans.crud.crudModel.isAllSelectedCategory = []
    },
    addSelectedCategory (id) {
      const filteredServices = this.getItemByCategory(id)
      // カテゴリーごとの全て選択がチェックされている場合
      if (this.plans.crud.crudModel.isAllSelectedCategory.includes(filteredServices[0].point_mst_category_id)) {
        // 選択状態を解除
        filteredServices.forEach(filteredService => {
          if (this.plans.crud.crudModel.selectedServices.includes(filteredService.id)) {
            const index = this.plans.crud.crudModel.selectedServices.indexOf(filteredService.id)
            this.plans.crud.crudModel.selectedServices.splice(index, 1)
          }
        })
        // 全て選択のチェック状態を解除
        const index = this.plans.crud.crudModel.isAllSelectedCategory.indexOf(filteredServices[0].point_mst_category_id)
        this.plans.crud.crudModel.isAllSelectedCategory.splice(index, 1)
      } else {
        // カテゴリーの項目を全て選択
        filteredServices.forEach(filteredService => {
          // 個別選択されていれば含めない
          if (this.plans.crud.crudModel.selectedServices.includes(filteredService.id)) {
            return
          }
          this.plans.crud.crudModel.selectedServices.push(filteredService.id)
        })
        // 全て選択のチェック状態をON
        this.plans.crud.crudModel.isAllSelectedCategory.push(filteredServices[0].point_mst_category_id)
      }
    },
    getItemByCategory: function (id) {
      const filteredServices = this.service.items.filter(function (item, index) {
        return (item.point_mst_category_id === id)
      })
      return filteredServices
    },
    editPlan (item) {
      this.plans.crud.editedIndex = this.plans.items.indexOf(item)
      this.fillCrudModel(item)
      this.filterSelectedServices()
      this.plans.crud.dialog = true
    },
    copyPlan (item) {
      // プラン複製処理
    },
    savePlan () {
      const _this = this
      if (_this.plans.crud.crudModel.services.size < 1) {
        _this.$store.commit('setFlashError', 'サービスを選択してください')
        return 0
      }
      const id = _this.plans.crud.crudModel.id
      const formData = new FormData()
      formData.append('contract_model', _this.plans.crud.crudModel.contract_model)
      formData.append('plan_name', _this.plans.crud.crudModel.plan_name)
      formData.append('plan_memo', _this.plans.crud.crudModel.plan_memo)
      formData.append('plan_admin_memo', _this.plans.crud.crudModel.plan_admin_memo)
      // changes in consumption input
      _this.updateConsumtions()
      // if service selection has been changed (OK btn in dialog)
      _this.filterSelectedServices()
      console.log('save action:', _this.plans.crud.crudModel.plan_mst_service)
      _this.plans.crud.crudModel.plan_mst_service.forEach((value) => {
        formData.append('plan_mst_services[]', JSON.stringify(value))
      })
      if (this.plans.crud.editedIndex > -1) { // edit
        formData.append('_method', 'PUT')
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/plan_mst/${id}`, formData)
            .then((res) => {
              console.log(res.data)
              _this.readDataFromAPI()
              _this.closePlanDialog()
              _this.$store.commit('setFlashSuccess', 'プランを変更しました。')
              resolve(res)
            })
            .catch(err => {
              console.log(err)
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
            })
        })
      } else { // new
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/plan_mst`, formData)
            .then((res) => {
              console.log(res.data)
              _this.readDataFromAPI()
              _this.closePlanDialog()
              _this.$store.commit('setFlashSuccess', 'プランを追加しました。')
              resolve(res)
            })
            .catch(err => {
              console.log(err)
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
            })
        })
      }
    },
    deletePlan () {
      console.info('delete -> plan id:', this.plans.crud.crudModel.id)
      const _this = this
      const id = _this.plans.crud.crudModel.id
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/plan_mst/${id}`)
        .then(res => {
          _this.$store.commit('setFlashSuccess', 'プランを削除しました。')
        })
        .catch(() => {
          _this.$store.commit('setFlashError', '入力内容に不備があります。入力内容をご確認ください。')
        })
        .finally(() => {
          _this.readDataFromAPI()
          _this.closePlanDialog()
          _this.$forceUpdate() // HACK: 再度更新できない問題があるため強制リロード
        })
    },
    closePlanDialog () {
      this.plans.crud.dialog = false
      this.$nextTick(() => {
        // set default value
        Object.assign(this.plans.crud, this.$options.data().plans.crud)
        this.plans.crud.isFormValid = this.$refs.form?.validate() // HACK: バリデーション結果情報が更新されない
      })
      this.readDataFromAPI()
    },
    serviceSelectionConfirm () {
      console.log(this.plans.crud.crudModel.selectedServices)
      this.filterSelectedServices()
      this.plans.crud.serviceDialog = false
    },
    getPlanUnit: function (typeId) {
      let unit = ''
      if (typeId === 'point') unit = 'pt'
      else if (typeId === 'unlimit') unit = '∞'
      return unit
    },
    fillCrudModel: function (item) {
      this.plans.crud.crudModel.id = item.id
      this.plans.crud.crudModel.plan_name = item.plan_name
      this.plans.crud.crudModel.contract_model = item.contract_model
      this.plans.crud.crudModel.plan_memo = item.plan_memo
      this.plans.crud.crudModel.plan_admin_memo = item.plan_admin_memo
      // create deep copy of item.services map<String category, Array services>
      this.plans.crud.crudModel.services = new Map(JSON.parse(JSON.stringify(Array.from(item.services))))
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of this.plans.crud.crudModel.services.entries()) {
        value.forEach((entry) => {
          // filter active services from map and push service' models id to selectedService[] model
          this.plans.crud.crudModel.selectedServices.push(entry.point_mst_id)
          // fill point variable for editing from parent entity.
          entry.point_mst.__point = entry.point
        })
      }
    },
    sortPlanItemsByContractModel: function () {
      this.plans.items.sort((a, b) => {
        const fa = a.plan_name
        const fb = b.plan_name
        if (fa < fb) return -1
        if (fa > fb) return 1
        return 0
      })
      this.plans.items.sort((a, b) => {
        const fa = a.contract_model
        const fb = b.contract_model
        if (fa < fb) return -1
        if (fa > fb) return 1
        return 0
      })
    },
    filterSelectedServices: function () {
      this.plans.crud.crudModel.plan_mst_service = this.plans.crud.crudModel.serviceList.filter((service) => {
        return this.plans.crud.crudModel.selectedServices.includes(service.id)
      })
      if (this.plans.crud.editedIndex !== -1) {
        this.setPointsForEdit()
      }
      this.sortServicesByCategoryCrud()
    },
    setPointsForEdit: function () {
      const map = new Map()
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of this.plans.crud.crudModel.services.entries()) {
        value.forEach((service) => {
          if (service.point_mst) {
            map.set(service.point_mst.id, service.point_mst.__point)
          }
        })
      }
      this.plans.crud.crudModel.plan_mst_service.forEach((service) => {
        if (map.has(service.id)) {
          service.__point = map.get(service.id)
        }
      })
    },
    sortServicesByCategoryCrud: function () { // updated category grouped map for crud dialog
      const map = new Map()
      this.plans.crud.crudModel.plan_mst_service.forEach((va) => {
        var category = ''
        if (va.point_mst_category) {
          category = va.point_mst_category.name
        } else {
          category = '未分類'
        }

        var arr
        if (map.has(category)) {
          arr = map.get(category)
          arr.push(va)
        } else {
          arr = []
          arr.push(va)
        }
        arr.sort((a, b) => (a.service > b.service) ? 1 : ((b.service > a.service) ? -1 : 0))
        map.set(category, arr)
      })
      this.plans.crud.crudModel.services = new Map([...map.entries()].sort())
      console.log(map)
    },
    updateConsumtions: function () {
      const tempArr = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of this.plans.crud.crudModel.services.entries()) {
        value.forEach((service) => {
          tempArr.push(service)
        })
      }
      this.plans.crud.crudModel.serviceList = tempArr
    },
    sortServicesToCategory: function () {
      this.plans.items.forEach((v) => {
        const map = new Map()
        v.plan_mst_service.forEach((va) => {
          var category = ''
          if (va.point_mst?.point_mst_category) {
            category = va.point_mst.point_mst_category.name
          } else {
            category = '未分類'
          }

          var arr
          if (map.has(category)) {
            arr = map.get(category)
            arr.push(va)
          } else {
            arr = []
            arr.push(va)
          }
          arr.sort((a, b) => (a.point_mst.service > b.point_mst.service) ? 1 : ((b.point_mst.service > a.point_mst.service) ? -1 : 0))
          map.set(category, arr)
        })
        v.services = new Map([...map.entries()].sort())
      })
      // console.log(this.plans.items)
    },
    sortServicesListToCategory: function () {}, // FIXME: TypeError: this.sortServicesListToCategory is not a function が発生するため空関数を定義
    reduceServiceItemsList: function () {
      const ids = this.service.items.map(o => o.id)
      this.service.items = this.service.items.filter((service, index) => !ids.includes(service.id, index + 1))
    },
    readDataFromAPI: function () {
      this.loading = true
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/plan_mst`)
        .then(res => {
          console.log(res.data)
          this.plans.items = res.data.planMstList
          this.service.items = res.data.pointMstList
          this.reduceServiceItemsList()
          this.plans.crud.crudModel.serviceList = this.service.items
          this.sortPlanItemsByContractModel()
          this.sortServicesToCategory()
          this.sortServicesListToCategory()
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })

      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/point_mst_category`)
        .then(res => {
          this.categories.items = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  computed: {
    formTitleService () {
      return this.plans.crud.editedIndex === -1 ? 'プラン追加' : 'プラン編集'
    }
  },
  created: function () {
    this.readDataFromAPI()
  }
}
</script>

<style scoped lang="scss">

.v-list-item__subtitle{
  white-space: pre-line;
}

::v-deep .v-data-table{

  .service-title{
    width: 30%!important;
  }

  .service-consumption{
    width: 8%;
    text-align: right!important;
    white-space: nowrap;
  }

}

// .service-dialog{
//   .v-card__text{
//     height: 50vh;
//   }
// }

.edit-plan{
  display: none;
}

.v-expansion-panel--active{
  .edit-plan{
    display: inline-block;
  }
}

td .v-text-field{
      width: 60px;
      float: right;
}

::v-deep .theme--light{
  .v-data-table{
    th{
      border-bottom: 0;
    }
    background: transparent;
    &:first-child{
      td{
        padding-top: 0!important;
      }
    }
    &:hover{
      background: transparent!important;
    }
    td{
      border: none!important;
    }
  }
}

.more{
  details{
    &:last-child{
      summary{
        border-bottom: none;
      }
    }
  }
  summary{
    cursor: pointer;
    margin-top: .6em;
    padding: 4px 0;
    border-bottom: 1px solid #eee;
  }
  pre{
    padding: .5em 0 .5em 1em;
  }
  // margin-top: 20px;

}
</style>
